import React, { useState, useEffect, useRef, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import { ThemeContext } from '../context/ThemeContext';

const MapboxComponent = ({ locations }) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const { theme } = useContext(ThemeContext);

    const mapStyle = theme === 'light'
        ? 'mapbox://styles/mapbox/light-v10'
        : 'mapbox://styles/mapbox/dark-v10';

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle,
        center: [0, 30],
        zoom: 2.5,
        maxZoom: 5.5
      });

      newMap.on("load", () => {
        // ipData should be defined here since it depends on the locations prop.
        const ipData = {
          type: "FeatureCollection",
          features: locations.map(location => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [Number(location.longitude), Number(location.latitude)],
            },
            properties: {
              latitude: Number(location.latitude),
            },
          })),
        };

        newMap.addSource("ipData", {
          type: "geojson",
          data: ipData,
        });

        newMap.addLayer({
          id: "points",
          type: "circle",
          source: "ipData",
          paint: {
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1, // minimum zoom
              3, // size at minimum zoom
              17, // maximum zoom
              16  // size at maximum zoom
            ],
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "latitude"],
              0,
              "#0fc0b0",
              150,
              "#71d0fb",
            ],
            "circle-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1, // minimum zoom
              0.7, // opacity at minimum zoom
              12, // maximum zoom
              0.5  // opacity at maximum zoom
            ],
          },
        });
        

  //       newMap.addLayer({
  //         id: "heatmap",
  //         type: "heatmap",
  //         source: "ipData",
  //         maxzoom: 15,
  //         paint: {
  //           // Increase the heatmap weight based on frequency and property magnitude
  //           "heatmap-weight": [
  //             "interpolate",
  //             ["linear"],
  //             ["get", "latitude"],
  //             0, 0,
  //             150, 1
  //           ],
  //           // Increase the heatmap color weight weight by zoom level
  //           // heatmap-intensity is a multiplier on top of heatmap-weight
  //           "heatmap-intensity": [
  //             "interpolate",
  //             ["linear"],
  //             ["zoom"],
  //             0, 1,
  //             15, 3
  //           ],
  //           // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
  //           "heatmap-color": [
  //             "interpolate",
  //             ["linear"],
  //             ["heatmap-density"],
  //             0, "rgba(33,102,172,0)",
  // 0.2, "rgb(253, 219, 199)", // Very light pink
  // 0.4, "rgb(255,182,193)", // LightPink
  // 0.6, "rgb(255,105,180)", // HotPink
  // 0.8, "rgb(255,20,147)",  // DeepPink
  // 1, "rgb(255,0,127)"  // Brighter Pink (Pinker than HotPink)
  //           ],
  //           // Adjust the heatmap radius by zoom level
  //           "heatmap-radius": [
  //             "interpolate",
  //             ["linear"],
  //             ["zoom"],
  //             0, 2,
  //             15, 20
  //           ],
  //           // Transition from heatmap to circle layer by zoom level
  //           "heatmap-opacity": [
  //             "interpolate",
  //             ["linear"],
  //             ["zoom"],
  //             3, 1,
  //             8, 0
  //           ],
  //         },
  //       });

        const layersToHide = ["state-label", "settlement-label", "poi-label"];
        for (let layer of newMap.getStyle().layers) {
          if (layersToHide.includes(layer.id)) {
            newMap.setLayoutProperty(layer.id, "visibility", "none");
          }

          if (layer.type === "line") {
            newMap.setPaintProperty(layer.id, "line-color", "#007cbf");
          }
        }
        // Set map to state here after everything is loaded and styled
        setMap(newMap);
      });
    };

    if (!map) initializeMap();

    // Cleanup on component unmount
    return () => {
      if (map) {
        map.remove();
      }
    };
    // eslint-disable-next-line
  }, [locations]);  // Dependency array now uses 'locations'

  return <div ref={mapContainer} style={{ width: '100%', height: '1100px' }} />;
};

export default MapboxComponent;
