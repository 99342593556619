import './App.css';
import MainPage from './pages/Main';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <MainPage/>
      </ThemeProvider>
    </div>
  );
}

export default App;
