import { useState, useEffect } from 'react';

export const useTorDetails = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const API_KEY = process.env.REACT_APP_IP_API_KEY;

  const getLatLonFromIPs = async (ips) => {
    try {
      const response = await fetch('https://api.ipgeolocation.io/ipgeo-bulk?apiKey=' + API_KEY, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ips }),
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        return data;
      } else {
        throw new Error('Unexpected response structure from IP geolocation API.');
      }
    } catch (error) {
      console.error('Error fetching IP coordinates:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch("https://onionoo.torproject.org/details");
        const responseData = await response.json();

        const filteredRelays = responseData.relays?.filter(
          (relay) => relay.contact && relay.contact.includes("@ator")
        );

        const ips = filteredRelays
          .map((relay) => relay.or_addresses[0]?.split(":")[0])
          .filter(Boolean);

        const batchSize = 49;
        const batches = Math.ceil(ips.length / batchSize);
        let allGeoData = [];

        for (let i = 0; i < batches; i++) {
          const batch = ips.slice(i * batchSize, (i + 1) * batchSize);
          const geoDataBatch = await getLatLonFromIPs(batch);
          allGeoData = [...allGeoData, ...geoDataBatch];
        }

        if (allGeoData) {
          setData(allGeoData);
        } else {
          setError({
            message: `Error fetching Tor details`,
            status: responseData?.status || 500,
          });
        }
      } catch (err) {
        const errMsg = err instanceof Error ? err.message : "An error occurred while fetching Tor details";
        setError({ message: errMsg });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
    // eslint-disable-next-line
  }, []);

  return {
    data,
    error,
    isLoading,
  };
};
