import React from "react";
import MapboxComponent from "../components/Mapbox";
import { useTorDetails } from "../hooks/useTorDetails";
import { FaGithub, FaMoon, FaThLarge } from "react-icons/fa";
import { GridLoader } from "react-spinners";
import { motion } from "framer-motion";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";

import { WarpFactory } from "warp-contracts";
import { EthersExtension } from "warp-contracts-plugin-ethers";

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};


const warp = WarpFactory
  .forMainnet()
  .use(new EthersExtension());

const contract = warp.contract(
  "LYtr_ztHqd4nFFSZyYN9_BWIinESJNBVzOJwo1u5dU0"
);


const MainPage = () => {
  const { data, isLoading, error } = useTorDetails();
  const { theme, setTheme } = useContext(ThemeContext);
  const [verifiedRelaysCount, setVerifiedRelaysCount] = useState(0);

  useEffect(() => {  // Provide the correct address
    fetchTotalVerifiedRelays();
  }, []); //

  async function fetchTotalVerifiedRelays(address) {
    if (!contract) return;
  
    try {
      const { result } = await contract.viewState({
        function: 'verified',
        address
      });
  
      const verifiedKeys = Object.keys(result);
      setVerifiedRelaysCount(verifiedKeys.length);
    } catch (error) {
      console.error("Error fetching verified relays:", error);
    }
  }

    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <GridLoader color="#0081c7" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <motion.div
  className="relative w-full h-screen"
  initial="hidden"
  animate="visible"
  variants={fadeIn}
>
  <MapboxComponent locations={data} key={theme} />

  <motion.div
    className="absolute top-10 left-1/2 transform -translate-x-1/2 w-3/5"
    initial="hidden"
    animate="visible"
    variants={fadeIn}
  >
    <div className="bg-black bg-opacity-40 text-white py-1 px-4 rounded-lg flex items-center shadow-md justify-between" style={{ minWidth: '250px' }}>
      
      {/* Logo and Name */}
      <div className="flex items-center text-lg">
        <img src="./logo.png" alt="Logo" className="w-12 h-12 mr-2" />
        <span className="hidden md:inline mr-2">Total Relays: </span>
        <span className="text-lightgreendarken1 mr-2">{verifiedRelaysCount}</span>
      </div>

      {/* Links */}
      <div className="flex-1 hidden lg:block text-center text-lg">
        <a
          href="/"
          className="transition-colors duration-300 ease-in-out hover:text-lightgreendarken1 hover:underline mr-8"
        >
          ATOR Relay Map Explorer
        </a>
      </div>

      {/* Icons */}
      <div className="ml-auto flex items-center">
        <a
          href="https://ator-dashboard.g8way.io/"
          target="_blank"
          rel="noopener noreferrer"
          className="transition-colors duration-300 ease-in-out hover:text-lightgreendarken1 hover:shadow-l mr-3"
        >
          <FaThLarge size={24} />
        </a>
        <button className="mr-3 transition-colors duration-300 ease-in-out hover:text-lightgreendarken1 hover:shadow-lg" onClick={toggleTheme}>
          <FaMoon size={20} />
        </button>
        <a
          href="https://github.com/ATOR-Development"
          target="_blank"
          rel="noopener noreferrer"
          className="transition-colors duration-300 ease-in-out hover:text-lightgreendarken1 hover:shadow-lg"
        >
          <FaGithub size={24} />
        </a>
      </div>
    </div>
  </motion.div>
</motion.div>
  );
};

export default MainPage;
